var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row form-group" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-10" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      class: _vm.errors.has("vi_title") ? "border-danger" : "",
                      attrs: {
                        placeholder: "Tiếng Việt",
                        "data-vv-name": "vi_title",
                        "data-vv-as": "Tiêu đề",
                      },
                      model: {
                        value: _vm.form.vi_title,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vi_title", $$v)
                        },
                        expression: "form.vi_title",
                      },
                    }),
                    _vm.errors.has("vi_title")
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.first("vi_title"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      class: _vm.errors.has("en_title") ? "border-danger" : "",
                      attrs: {
                        placeholder: "Tiếng Anh",
                        "data-vv-name": "en_title",
                        "data-vv-as": "Tiêu đề",
                      },
                      model: {
                        value: _vm.form.en_title,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "en_title", $$v)
                        },
                        expression: "form.en_title",
                      },
                    }),
                    _vm.errors.has("en_title")
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.first("en_title"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row form-group" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-10" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-9" },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                        },
                      ],
                      class: _vm.errors.has("distance") ? "border-danger" : "",
                      attrs: {
                        type: "number",
                        placeholder: "Giá trị",
                        "data-vv-name": "distance",
                        "data-vv-as": "Giá trị",
                      },
                      model: {
                        value: _vm.form.distance,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "distance", $$v)
                        },
                        expression: "form.distance",
                      },
                    }),
                    _vm.errors.has("distance")
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.first("distance"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "full-width",
                        attrs: { placeholder: "Đơn vị" },
                        model: {
                          value: _vm.form.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "unit", $$v)
                          },
                          expression: "form.unit",
                        },
                      },
                      _vm._l(_vm.unitOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Lưu lại")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tiêu đề")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Giá trị")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }